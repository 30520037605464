<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Inventory
            </h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a
                    href="/"
                    class="router-link-active"
                    target="_self"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="align-text-top feather feather-home"
                  >
                    <path
                      d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                    />
                    <polyline points="9 22 9 12 15 12 15 22" /></svg></a>
                </li>
                <li class="breadcrumb-item">
                  <a
                    href="#"
                    target="_self"
                    class=""
                  >Pages </a>
                </li>
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Edit </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-form @submit.prevent>
      <b-card>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Purchase Date "
              label-for="Purchase-Date "
            >
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="value"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="value"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="example-input"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Hatchery"
              label-for="Hatchery"
            >
              <b-form-select
                v-model="selected"
                :options="options"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Source"
              label-for="Source"
            >
              <b-form-select
                v-model="selected"
                :options="options"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Breed"
              label-for="Breed"
            >
              <b-form-select
                v-model="selected"
                :options="options"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="City"
              label-for="mc-city"
            >
              <b-form-input
                id="mc-city"
                placeholder="City"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Country"
              label-for="mc-country"
            >
              <b-form-input
                id="mc-country"
                placeholder="Country"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Flock id"
              label-for="Flock id"
            >
              <div class="form-label-group">
                <b-form-input
                  id="Flock-id"
                  placeholder="Flock Id"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="Egg batch ID"
              label="Egg batch ID"
            >
              <div class="form-label-group">
                <b-form-input
                  id="Egg-batch-ID"
                  type="email"
                  placeholder="Egg batch ID"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Egg laid date "
              label-for="Egg-laid-date "
            >
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="value"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="value"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="example-input"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Flock id"
              label-for="No. of egg received"
            >
              <div class="form-label-group">
                <b-form-input
                  id="no-egg"
                  placeholder="No. of egg received"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Damage/Rejected"
              label-for="Damage/Rejected"
            >
              <div class="form-label-group">
                <b-form-input
                  id="Flock-id"
                  placeholder="Flock Id"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Price Per Egg"
              label-for="Flock id"
            >
              <div class="form-label-group">
                <b-form-input
                  id="Flock-id"
                  placeholder="Price Per Egg"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Flock Age"
              label-for="Flock Age"
            >
              <div class="form-label-group">
                <b-form-input
                  id="Flock-age"
                  placeholder="Flock Age"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Total Cost"
              label-for="Total Cost"
            >
              <div class="form-label-group">
                <b-form-input
                  id="Total Cost"
                  placeholder="Total Cost"
                />
              </div>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              id="checkbox-10"
              name="checkbox-10"
              value="Remember_me"
            >
              Remember me
            </b-form-checkbox>
          </b-form-group>
        </b-col> -->

          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormSelect,
    BFormDatepicker,
    flatPickr,
    vSelect,
    Logo,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients').then(response => {
      clients.value = response.data
    })

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }

    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer']

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
